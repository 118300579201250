@import 'default';
@font-face {
	font-family: 'fbook';
	src: url('./fonts/fbook/futura-book-font.eot'); /* IE9+ */
	src: url('./fonts/fbook/futura-book-font.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/fbook/futura-book-font.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/fbook/futura-book-font.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/fbook/futura-book-font.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/fbook/futura-book-font.svg#fontname') format('svg'); /* iOS 4.1- */
}
@font-face {
	font-family: 'fmbt';
	src: url('./fonts/fmbt/fmbt.eot'); /* IE9+ */
	src: url('./fonts/fmbt/fmbt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/fmbt/fmbt.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/fmbt/fmbt.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/fmbt/fmbt.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/fmbt/fmbt.svg#fontname') format('svg'); /* iOS 4.1- */
}
@font-face {
	font-family: 'futur';
	src: url('./fonts/futur/futur.eot'); /* IE9+ */
	src: url('./fonts/futur/futur.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/futur/futur.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/futur/futur.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/futur/futur.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/futur/futur.svg#fontname') format('svg'); /* iOS 4.1- */
}
@font-face {
	font-family: 'mbold';
	src: url('./fonts/mbold/mbold.eot'); /* IE9+ */
	src: url('./fonts/mbold/mbold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/mbold/mbold.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/mbold/mbold.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/mbold/mbold.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/mbold/mbold.svg#fontname') format('svg'); /* iOS 4.1- */
}
@font-face {
	font-family: 'mr';
	src: url('./fonts/mr/mr.eot'); /* IE9+ */
	src: url('./fonts/mr/mr.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/mr/mr.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/mr/mr.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/mr/mr.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/mr/mr.svg#fontname') format('svg'); /* iOS 4.1- */
}

@mixin adorn($bgcolor) {
    position: relative;
    padding: {
        bottom: 20px;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        background-color: $bgcolor;
    }

    &:after {
        bottom: 0;
        margin: {
            left: 60px;
        }
        width: 50px;
        height: 6px;
    }

    &:before {
        bottom: (6px + 4px);
        margin: {
            left: (60px + 20px);
        }
        width: 30px;
        height: 6px;
    }
    @include media(420px) {
        &:after {
            margin: {
                left: 40px;
            }
        }
        &:before {
            margin: {
                left: (40px + 20px);
            }
        }
    }
}

$primaryColor: #fdcc1c;
$darken: #4d4d4d;
$gray: #f1f2f2;
$tfz: 30px, 22px;

%none {
    display: none;
    visibility: hidden;
    font-size: 0;
    width: 0;
    height: 0;
    @include opacity(0);
}
%titleFont {
    font: nth($list: $tfz, $n: 1) 'futur', sans-serif;
    @include media(420px) {
        font-size: nth($list: $tfz, $n: 2);
    }
}

#masthead {
    position: relative;
    padding: 0;
    background-color: $primaryColor;
    border: {
        top: 4px solid white;
    }

    .site-logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-60%);
        z-index: 999;
        max-width: 240px;
        margin: {
            bottom: 0;
        }
        padding: 20px;
        border-radius: 10px;
        background-color: $primaryColor;
        @include media(1024px) {
            position: relative;
            display: block;
        }
    }

    #menu-nav {
        font-size: 0;

        li {
            padding: 20px 40px;
            @include media(1024px) {
                padding: 0;
            }

            a {
                text-transform: uppercase;
                color: #000;
                font-weight: normal;
                font-family: Arial, Helvetica, sans-serif;
                @include media(1024px) {
                    font-size: 16px;
                    color: $primaryColor;
                }
            }

            &.current-menu-item,
            &.current_page_item {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 26px;
                    transform: translateY(-50%);
                    border: {
                        top: 6px solid black;
                        left: 4px solid transparent;
                        right: 4px solid transparent;
                    }
                }

                a {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                }
            }
        }

        #menu-item-46 {
            margin: {
                left: 24%;
            }
            @include media(1024px) {
                margin: {
                    left: 0;
                }
            }
        }
    }

    .btn-menu {
        margin: {
            bottom: 10px;
        }
        line-height: 1;
    }
}
.header-clone {
    @extend %none;
}
.site-footer {
    p {
        line-height: 1.2;
    }
}

// Home
.company {
    position: relative;
    padding: {
        bottom: 20px;
    }

    .img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        margin: {
            top: -15%;
        }
    }

    .vc_custom_heading {
        &:nth-child(2) {
            margin: {
                top: 15%;
            }
        }
    }

    h1 {
        @extend %titleFont;
    }

    .thin {
        margin: {
            bottom: 20px;
        }
        font-family: "fbook";
        @include adorn($primaryColor);
    }

    p {
        font: 18px/1.2 "fbook";

        + p {
            padding: {
                top: 14px;
            }
        }
    }

    .vc_btn3 {
        margin: {
            top: 20px;
        }
        padding: 2px 10px;
        background-color: $primaryColor !important;
        border: none;
        color: black !important;
        font-family: "futur";

        &:hover {
            border: none;
            background-color: transparent !important;
        }
    }
}
.choose {
    .title {
        position: relative;
        padding: {
            bottom: 20px;
        }
        @extend %titleFont;

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: #666;
            left: 50%;
        }

        &:after {
            bottom: 0;
            margin: {
                left: 92px;
            }
            width: 50px;
            height: 6px;
        }

        &:before {
            bottom: (6px + 4px);
            margin: {
                left: (92px + 20px);
            }
            width: 30px;
            height: 6px;
        }
    }

    .text-wrap {
        padding: {
            top: 20px;
        }
        margin: {
            left: 30%;
        }
        width: 440px;
        @include media(1366px) {
            margin: {
                left: 20%;
            }
        }
        @include media(768px) {
            margin: {
                left: 10%;
            }
        }
        @include media(420px) {
            padding: {
                bottom: 30px;
            }
        }

        p {
            font-size: 18px;
            line-height: 1.2;
            @include media(768px) {
                font-size: 14px;
            }

            .space {
                display: block;
                width: 100%;
                height: 6px;
            }
        }

        strong {
            display: inline-block;
            padding: {
                top: 30px;
                bottom: 4px;
            }    
            font-family: "fmbt";
            @include media(1024px) {
                padding: {
                    top: 20px;
                }
            }
            @include media(768px) {
                padding: {
                    top: 0;
                }
            }
        }
    }

    .icon-wrap {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -55%);
        @include media(1440px) {
            transform: translate(50%, -52%);
        }
        @include media(1366px) {
            transform: translate(50%, -45%);
        }
        @include media(1280px) {
            transform: translate(50%, -42%);
        }
        @include media(1024px) {
            transform: translate(50%, -32%);
        }
        @include media(420px) {
            right: 50%;
            top: 100%;
            transform: translate(50%, -50%);
            text-align: center;
        }

        .wpb_single_image {
            width: 100px;
            @include media(768px) {
                width: 50px;
            }
            @include media(420px) {
                display: inline-block;
            }

            + .wpb_single_image {
                margin: {
                    top: 50px;
                }
                @include media(768px) {
                    margin: {
                        top: 10px;
                    }
                }
            }
        }
    }
}
.services {
    padding: {
        top: 20px;
    }
    
    &.mt {
        margin: {
            top: 40px;
        }
    }

    .title {
        @extend %titleFont;
        @include adorn(#fdd222);
    }

    .woocommerce {
        padding: {
            top: 40px;
        }

        .button {
            padding: 5px 10px;
            margin: {
                top: 0 !important;
            }
            border-radius: 0;
            border: 2px solid $primaryColor;
            color: black;
            font-family: "fbook", sans-serif;
            font-size: 12px;

            &:hover {
                color: $primaryColor;
                background-color: black;
            }
        }
    }

    .woocommerce-LoopProduct-link {
        position: relative;
        display: block;

        &:hover {
            .woocommerce-loop-product__title {
                @include opacity(1);
                transition: opacity .4s linear;

                span {
                    top: 50%;
                    @include opacity(1);
                    transition: top .1s linear .2s,
                    opacity .1s linear .2s;
                }
            }
        }
    }

    .woocommerce-loop-product__title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: $primaryColor, $alpha: .8);
        @include opacity(0);

        span {
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
            color: black;
            @extend %titleFont;
            @include opacity(0);
        }
    }
}
.form-wrap {
    .title {
        @extend %titleFont;
        @include adorn(#4d4d4d);
    }

    .note {
        padding: {
            top: 20px;
            bottom: 10px;
        }
        font: 18px/1.2 "mr", sans-serif;
    }

    .wpcf7-form {
        overflow: hidden;
        width: 65%;
        padding: {
            bottom: 50px;
        }
        @include media(768px) {
            width: 100%;
        }

        label {
            width: 100%;
            color: #4d4d4d;
            font-family: "mr";
            @include media(768px) {
                padding: {
                    top: 20px;
                }
            }
        }

        %input {
            width: 100%;
            border: none;
            background-color: rgba($color: $primaryColor, $alpha: .5);

            &:focus {
                outline: 0;
                border: none;
            }
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"] {
            @extend %input;
            height: 40px;
        }

        textarea {
            @extend %input;
            height: 140px;
            resize: none;
        }

        input[type="submit"],
        input[type="reset"] {
            padding: 1px 10px;
            border-radius: 0;
            color: #333;

            &:focus {
                outline: 0;
            }
        }

        input[type="submit"] {
            margin: {
                left: 10px;
            }
        }

        .btn-wrap {
            text-align: right;
        }

        .ajax-loader {
            @extend %none;
        }
    }
}
.address {
    .vc_custom_1528426333147 {
        padding: {
            top: 0 !important;
        }
    }

    .wpb_single_image {
        padding: {
            top: 6%;
        }
        text-align: left !important;
        @include media(420px) {
            padding: {
                top: 0;
            }
        }

        img {
            width: 260px !important;
        }
    }

    .wpb_single_image,
    .info {
        padding: {
            left: 23% !important;
        }
        @include media(1600px) {
            padding: {
                left: 20% !important;
            }
        }
        @include media(1440px) {
            padding: {
                left: 17% !important;
            }
        }
        @include media(1366px) {
            padding: {
                left: 13% !important;
            }
        }
        @include media(1024px) {
            padding: {
                left: 2% !important;
            }
        }
        @include media(420px) {
            padding: {
                left: 10% !important;
            }
        }
    }
    
    .info {
        padding: {
            top: 20px;
        }
        @include media(420px) {
            padding: {
                bottom: 40px !important;
            }
        }

        li {
            position: relative;
            padding: {
                left: 34px;
            }
            list-style: none;
            font-family:"Mr";
            line-height: 1.2 !important;

            + li {
                margin: {
                    top: 15px;
                }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                }
            }

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    &:before {
                        background: url('./images/add#{$i}.png') center center/contain no-repeat;
                    }
                }
            }
        }
    }
}

//  about page

.about-content {
    padding: {
        bottom: 50px;
    }
    @include media(420px) {
        padding: {
            bottom: 30px;
        }
    }

    .about-text {
        padding: 50px 0 20px;
        @include media(420px) {
            padding: 30px 0;
        }
    
        .title {
            @extend %titleFont;
            font-family: "fbook";
    
            b {
                display: inline-block;
                padding: {
                    bottom: 10px;
                }
                border: {
                    bottom: 2px solid $primaryColor;
                }
                font-family: "fmbt";
            }
        }
    }    
    .text {
        width: 80%;
        margin: 35px auto 0;
        line-height: 1.2;
        @include media(420px) {
            width: 94%;
            margin: 20px auto 0;
        }

        p + p {
            padding: {
                top: 15px;
            }
        }
    }
    .features {
        position: relative;
        margin: 40px 0 0;
        @include media(420px) {
            margin: 20px 0 0;
        }
        
        .wpb_single_image {
            width: 50%;
            float: right;
            @include media(420px) {
                width: 100%;
            }
        }
    
        .wpb_raw_code {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 50%;
            @include media(420px) {
                position: relative;
                top: 0;
                transform: translateY(0);
                width: 100%;
            }
        }
    
        .item {
            padding: 8%;
            @include media(1024px) {
                padding: 6%;
            }
    
            &.s-gray {
                background-color: $gray;
            }
    
        }
    
        .title {
            display: inline-block;
            padding: 2px 10px;
            background-color: $primaryColor;
            font: bold 24px "Arial";
        }
    
        .info {
            padding: 4px 0;
            font: 18px "fbook";
        }
    }
}
// contact page
.contact-title {
    margin: 30px 0;
    @extend %titleFont;
    @include adorn($primaryColor);

    &:before {
        margin: {
            left: (38px + 20px);
        }
    }

    &:after {
        margin: {
            left: 38px;
        }
    }
}
.contact-address {
    padding: 20px 0 50px;
    @include media(420px) {
        padding: 10px 0 30px;
    }

    .text {
        @include media(420px) {
            padding: {
                top: 20px;
                left: 0;
            }
        }

        .info {
            display: inline-block;
            margin: {
                right: 30px;
            }
            list-style: none;
            font: 18px "mr";
            &:hover, 
            &:active, 
            &:link, 
            &:visited{
                color: black !important;
            }
            > a {
                color: black !important;
                
                &:hover, 
                &:active, 
                &:link, 
                &:visited{
                    color: black !important;
                }
            }

            @media screen and (min-width: 769px) {
                &:nth-child(2),
                &:nth-child(3) {
                    width: 100%;
                }
            }
            @include media(1024px) {
                margin: {
                    right: 20px;
                }
            }
            @include media(420px) {
                margin: {
                    right: 40px;
                }
            }

            + .info {
                margin: {
                    top: 10px;
                }
            }

            .sub-title {
                font: normal 16px "mr";
                color: black;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin: {
                        right: 10px;
                    }
                }
            }

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    .sub-title {
                        &:before {
                            @if $i == 1 {

                                background: url('./images/add1#{$i}.png') center center/contain no-repeat;
                            }@else if $i == 2 {
                                background: url('./images/add11.png') center center/contain no-repeat;

                            } @else {
                                $i: $i - 1;

                                background: url('./images/add1#{$i}.png') center center/contain no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }

    .logo-en,
    .logo-cn {
        color: #c1272d;
    }

    .logo-en {
        padding: {
            top: 12%;
        }
        font: bold 26px/1.2 Arial;
        @include media(420px) {
            padding: {
                top: 0;
            }
        }
    }

    .logo-cn {
        font: bold 26px "Microsoft YaHei";
    }
}

// product content page
@media only screen and (min-width: 769px) {

    .woocommerce .related.products ul.products li.product {
        $w: 100% / 4 - 1%;
        width: $w !important;
        margin: {
            right: (4% / 3);
        }
    }
}
.product-wrap {
    .woocommerce-breadcrumb,
    .summary,
    .entry-title,
    .price,
    .product_meta,
    .woocommerce-tabs,
    #secondary {
        @extend %none;
    }

    #primary {
        width: 100%;
        padding: {
            top: 40px;
        }
    }

    .service-title {
        text-align: center;
        @extend %titleFont;
        @include adorn($primaryColor);
        color: $primaryColor;
    }

    .product-title,
    .related h2 {
        width: 100%;
        margin: {
            top: 40px;
        }
        padding: 10px 0;
        background-color: $primaryColor;
        text-transform: capitalize;
        text-align: center;
        font: 26px "futur";
        color: black;
    }

    .product-title {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 1920px;
    }

    .product {
        padding: {
            top: 20px;
        }
    }

    .woocommerce-product-gallery {
        position: relative;
        float: none !important;
        width: 100% !important;
        margin: {
            bottom: 20px !important;
        }
        padding: 20px 15px 0;
        overflow: hidden;
        @include media(420px) {
            padding: {
                left: 0;
            }
        }
        .flex-viewport {
            float: left;
            width: 48%;
            @include media(420px){
                float: none;
                width: 100%;
            }
        }

        .flex-control-nav {
            float: right;
            width: 50%;
            padding: 0;
            @include media(420px){
                float: none;
                width: 100%;
            }

            li {
                width: (100% / 5);
            }

            img {
                opacity: 1 !important;
            }
        }

        .z-current {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba($color: $primaryColor, $alpha: .7);
            }
        }
    }
    
    .related {
        clear: both;

        h2 {
            margin: {
                bottom: 40px;
            }
            font: 20px "fbook";
        }

        .products {
            padding: 0 15px;
        }

        .product {
            margin: {
                bottom: 20px !important;
            }

            img {
                margin: {
                    bottom: 0 !important;
                }
            }
        }

        .button {
            @extend %none;
        }

    }
    .woocommerce-LoopProduct-link {
        position: relative;
        display: block;

        &:hover {
            .woocommerce-loop-product__title {
                @include opacity(1);
                visibility: visible;
                transition: opacity .4s linear;
            }

            span {
                top: 50%;
                @include opacity(1);
                transition: top .2s linear .1s,
                opacity .1s linear .1s;
            }
        }

        .woocommerce-loop-product__title {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($color: $primaryColor, $alpha: .5);
            @include opacity(0);
            visibility: hidden;

            
        }

        span {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: black;
            @include opacity(0);
        }
    }
}